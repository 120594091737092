
import BaseInputOutlined from '@/components/inputs/BaseInputOutlined.vue'
import { v4 as uuidv4 } from 'uuid'
import { computed, defineComponent, ref, shallowRef, watch, PropType, onMounted, getCurrentInstance } from 'vue'
import CustomActivatorSelect from '../inputs/CustomActivatorSelect.vue'
import moment from 'moment-timezone'
import { useProject } from '@root/src/hooks/useProject'
export default defineComponent({
    components: { BaseInputOutlined, CustomActivatorSelect },
    props: {
        value: { type: null, required: true },
        selectCurrentTimezone: { type: Boolean, default: false },
        recommendCurrentTimezone: { type: Boolean, default: false },
        label: { type: String, default: '' },
    },
    setup(props, { emit }) {
        const root = getCurrentInstance().proxy
        const {project} = useProject()
        const tzList = ref(
            [
                ...moment.tz.zonesForCountry('RU'),
                ...moment.tz.zonesForCountry('UA'),
                ...moment.tz.zonesForCountry('BY'),
                ...moment.tz.zonesForCountry('KZ'),
                ].map(el =>
            {
                let currTz = moment.tz(el).format('Z').split(':')[0]
                currTz = currTz[1] === '0' && currTz[2] !== '0' ? currTz.replace('0','') : currTz //Убираем лишний 0 перед временем часового пояса
                return ({ id: el, name: el + ' ' + currTz})
            })
        )
        const changeTimezone = ((timezone)=>{
            const projectId = project.value.id
            root.$store.commit('changeTimezone', {timezone, projectId})
            emit('input', timezone)
        })

        onMounted(() => {
            const currentTimezone = moment.tz.guess(true)
            if (tzList.value.map(el => el.id).includes(currentTimezone)) {
                if (props.selectCurrentTimezone) {
                    emit('input', currentTimezone)
                }
                if (props.recommendCurrentTimezone) {
                    tzList.value = [
                        ...tzList.value
                            .filter(({ id }) => id === currentTimezone)
                            .map(el => ({ ...el, recomended: true })),
                        ...tzList.value.filter(({ id }) => id !== currentTimezone),
                    ]
                }
            }
        })

        return { tzList, emit, changeTimezone }
    },
})
